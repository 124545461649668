@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    /* Variables Shadcn */

    --background: #ffffff; /* White*/
    --foreground: #1C1E1D; /* Gray 950 */

    --card: #ffffff; /* White*/
    --card-foreground: #1C1E1D; /* Gray 950 */
 
    --popover: #ffffff; /* White */
    --popover-foreground: #1C1E1D; /* Gray 950 */
 
    --muted: #E5E7EB; /* Light Accent 200 */
    --muted-foreground: #6B7280; /* Gray 500 */
 
    --accent: #E5E7EB; /* Light Accent 200 */
    --accent-foreground: #0f172a; /* Light Accent 100 */
 
    --destructive: #EF4444; /* Error 500 */
    --destructive-foreground: #111827; /* Gray 900 */

    --border: #D1D5DB; /* Gray 300 */
    --input: #D1D5DB; /* Gray 300 */
    --ring: #EBBB2A; /* Primary 700 */

    --shadow-color: rgba(56, 71, 109, 0.09);
 
    --radius: 0.5rem;

    /* White and Black */
    --white: #FFFFFF; /* White */
    --black: #000000; /* Black */

    /* Accent */
    --accent-100: #FFFFFF; /* Accent 100 */
    --accent-500: #1C1E1D; /* Accent 500 */
    --accent-900: #000000; /* Accent 900 */
    
    /* Gray */
    --gray-50: #F9FAFB; /* Gray 50 */
    --gray-100: #F3F4F6; /* Gray 100 */
    --gray-200: #E5E7EB; /* Gray 200 */
    --gray-300: #D1D5DB; /* Gray 300 */
    --gray-400: #9CA3AF; /* Gray 400 */
    --gray-500: #6B7280; /* Gray 500 */
    --gray-600: #4B5563; /* Gray 600 */
    --gray-700: #374151; /* Gray 700 */
    --gray-800: #1F2937; /* Gray 800 */
    --gray-900: #111827; /* Gray 900 */
    --gray-950: #030712; /* Gray 950 */

    /* Primary */
    --primary-100: #FFF9D9; /* Primary 100 */
    --primary-200: #FBE56E; /* Primary 200 */
    --primary: #F9DE4A; /* Primary 500 */
    --primary-700: #EBBB2A; /* Primary 700 */
    
    /* Secondary */
    --secondary-50: #F0F5FF; /* Secondary 50 */
    --secondary-100: #E0E7FF; /* Secondary 100 */
    --secondary-200: #C7D2FE; /* Secondary 200 */
    --secondary: #6366F1; /* Secondary 500 */
    --secondary-600: #4F46E5; /* Secondary 600 */
    --secondary-700: #4338CA; /* Secondary 700 */
    
    /* Success */
    --success-300: #86EFAC; /* Success 300 */
    --success-500: #22C55E; /* Success 500 */
    --success-600: #16A34A; /* Success 600 */
    --success-900: #14532D; /* Success 900 */

    /* Error */
    --error-300: #FCA5A5; /* Error 300 */
    --error-500: #EF4444; /* Error 500 */
    --error-600: #DC2626; /* Error 600 */
    --error-900: #7F1D1D; /* Error 900 */

    /* Warning */
    --warning-300: #FDE047; /* Warning 300 */
    --warning-500: #FACC15; /* Warning 500 */
    --warning-600: #EAB308; /* Warning 600 */
    --warning-900: #CA8A04; /* Warning 900 */

    /* Info */
    --info-300: #93C5FD; /* Info 300 */
    --info-500: #3B82F6; /* Info 500 */
    --info-600: #2563EB; /* Info 600 */
    --info-900: #1E3A8A; /* Info 900 */
  }
 
  .dark {
    --background: #010816; /* Accent 500 */
    --foreground: #E5E7EB; /* Gray 200 */
 
    --card: #010816; /* Accent 500 */
    --card-foreground: #E5E7EB; /* Gray 200 */
 
    --popover: #010816; /* Accent 500 */
    --popover-foreground: #E5E7EB; /* Gray 200 */
 
    --muted: #1e293b; /* Gray 800 */
    --muted-foreground: #94a3b7; /* Gray 400 */
 
    --accent: #0f172a;  /* Accent 500 */
    --accent-foreground: #E5E7EB; /* Gray 200 */
 
    --destructive: #EF4444; /* Error 500 */
    --destructive-foreground: #E5E7EB; /* Gray 200 */
 
    --border: #374151; /* Gray 700 */
    --input: #374151; /* Gray 700 */
    --ring: #EBBB2A; /* Primary 700 */

    --shadow-color: rgba(56, 71, 109, 0.09)

    /* White and Black */
    --white: #FFFFFF; /* White */
    --black: #000000; /* Black */

    /* Accent */
    --accent-100: #000000; /* Accent 900 */
    --accent-500: #1C1E1D; /* Accent 500 */
    --accent-900:  #E5E7EB; /* Gray 200 */

    /* Gray */
    --gray-50: #030712; /* Gray 950 */
    --gray-100: #111827; /* Gray 900 */
    --gray-200: #1F2937; /* Gray 800 */
    --gray-300: #374151; /* Gray 700 */
    --gray-400: #4B5563; /* Gray 600 */
    --gray-500: #6B7280; /* Gray 500 */
    --gray-600: #9CA3AF; /* Gray 400 */
    --gray-700: #D1D5DB; /* Gray 300 */
    --gray-800: #E5E7EB; /* Gray 200 */
    --gray-900: #F3F4F6; /* Gray 100 */
    --gray-950: #F9FAFB; /* Gray 50 */

    /* Primary */
    --primary-100: #FFF9D9; /* Primary 100 */
    --primary-200: #FBE56E; /* Primary 200 */
    --primary: #F9DE4A; /* Primary 500 */
    --primary-700: #EBBB2A; /* Primary 700 */
    
    /* Secondary */
    --secondary-100: #E0E7FF; /* Secondary 100 */
    --secondary-200: #C7D2FE; /* Secondary 200 */
    --secondary: #6366F1; /* Secondary 500 */
    --secondary-700: #4338CA; /* Secondary 700 */
    
    /* Success */
    --success-300: #86EFAC; /* Success 300 */
    --success-500: #22C55E; /* Success 500 */
    --success-600: #16A34A; /* Success 600 */
    --success-900: #14532D; /* Success 900 */

    /* Error */
    --error-300: #FCA5A5; /* Error 300 */
    --error-500: #EF4444; /* Error 500 */
    --error-600: #DC2626; /* Error 600 */
    --error-900: #7F1D1D; /* Error 900 */

    /* Warning */
    --warning-300: #FDE047; /* Warning 300 */
    --warning-500: #FACC15; /* Warning 500 */
    --warning-600: #EAB308; /* Warning 600 */
    --warning-900: #CA8A04; /* Warning 900 */

    /* Info */
    --info-300: #93C5FD; /* Info 300 */
    --info-500: #3B82F6; /* Info 500 */
    --info-600: #2563EB; /* Info 600 */
    --info-900: #1E3A8A; /* Info 900 */
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer base {
  h1, h2, h3, h4, h5, h6 {
    @apply font-display;
  }

  body {
    @apply font-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  input, textarea, select, button {
    @apply font-body;
  }
}

@layer components {
  .spinner {
      @apply w-10 h-10 border-4 border-blue-500 border-dotted rounded-full;
      border-top-color: transparent;
      animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  pointer-events: auto !important;
}

html {
  height: -webkit-fill-available;
}

/* Css custom classes */

.react-pdf__Page {
  margin: 0 !important;
  padding: 0 !important;
}

body {
  -ms-content-zooming: none;
}

* {
  touch-actions: pan-x pan-y;
}

.react-datepicker__current-month::first-letter {
  text-transform: capitalize;
}

.react-datepicker__current-month {
  color: var(--gray-800) !important;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--gray-800) !important;
}

.react-datepicker__day:hover, .react-datepicker__day:focus, .react-datepicker__time-list-item:hover {
  background-color: var(--primary-100) !important;
}

.react-datepicker__header, .react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__time-list-item--selected {
  background-color: var(--primary) !important;
}

.react-datepicker {
  border: 1px solid var(--gray-300) !important;
}

.react-datepicker__time-container {
  border-left: 1px solid var(--gray-300) !important;
}

.react-datepicker__header {
  border-bottom: 1px solid var(--gray-300) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: var(--primary) !important
}

.PhoneInputInput, .PhoneInputInput:hover, .PhoneInputInput:focus, .PhoneInputInput:active {
  font-size: 0.875rem;
  border: 0;
  height: 2.3rem;
}

[type='tel']:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

.select__input {
  box-shadow: none !important;
}

#quantity-input::-webkit-inner-spin-button,
#quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#quantity-input {
  -moz-appearance: textfield;
}

/* Editor Tip Tap Custom Classes */

.tiptap {
  height: 100%;
  min-height: 100vh;
  outline: none;
  width: 21cm;
  min-height: 29.7cm;
  padding: 2.5cm;
}

.tiptap > * + * {
  margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
}

.tiptap ul {
  list-style: disc;
}

.tiptap ol {
  list-style: decimal;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

.tiptap pre {
  background: var(--gray-900);
  color: var(--accent-100);
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.tiptap img {
  max-width: 100%;
  height: auto;
}

.tiptap blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.tiptap hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

span.field-template {
  background-color: var(--secondary); 
  color: #fff;
  padding: 0.5px 2px;
  border-radius: 4px; 
  transition: color 0.2s ease;
}

.tiptap[contenteditable="true"] span.field-template {
  cursor: pointer;
}

.tiptap[contenteditable="true"] span.field-template:hover {
  background-color: var(--secondary-700);
}

.tiptap a {
  color: var(--secondary-200);
  text-decoration: underline;
  cursor: pointer;
}

.tiptap .is-editor-empty:first-child::before {
  color: var(--gray-500);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

[contenteditable="false"]:focus {
  outline: none;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@media (max-width: 1380px) {
  .readonly-tiptap .tiptap {
    width: 100%;
    min-width: 700px;
    padding: 3rem;
  }
}

@media (max-width: 1280px) {
  .readonly-tiptap .tiptap {
    width: 100%;
    min-width: 600px;
    padding: 3rem;
  }
}

@media (max-width: 1180px) {
  .readonly-tiptap .tiptap {
    width: 100%;
    min-width: 500px;
    padding: 2.5rem;
  }
}

@media (max-width: 1080px) {
  .readonly-tiptap .tiptap {
    width: 100%;
    min-width: 400px;
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .tiptap {
    width: 90vw;
    padding: 1rem;
  }

  .tiptap > * + * {
    margin-top: 0.5em;
  }

  .tiptap ul,
  .tiptap ol {
    padding: 0 0.5rem;
  }

  .tiptap pre {
    padding: 0.5rem;
  }

  .tiptap blockquote {
    padding-left: 0.5rem;
  }

  .tiptap hr {
    margin: 1rem 0;
  }
}

@media (max-width: 640px) {
  .tiptap {
    width: 90vw;
    padding: 1rem;
  }

  .tiptap > * + * {
    margin-top: 0.5em;
  }

  .tiptap ul,
  .tiptap ol {
    padding: 0 0.5rem;
  }

  .tiptap pre {
    padding: 0.5rem;
  }

  .tiptap blockquote {
    padding-left: 0.5rem;
  }

  .tiptap hr {
    margin: 1rem 0;
  }

  .readonly-tiptap .tiptap {
    width: 100%;
    min-width: 300px;
    max-width: 100%;
    padding: 2rem;
  }
}

/* Custom ScrollBar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--gray-200);
}

::-webkit-scrollbar-thumb {
  background: var(--gray-300);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400);
}

/* Hidden Recaptcha */
.grecaptcha-badge { 
  visibility: hidden !important;
}

/* Remove ring */
:focus {
  outline: none;
}

/* Progress Bar */
.progress {
  animation: progress 1s infinite linear;
}

.left-right {
    transform-origin: 0% 50%;
}

@keyframes progress {
  0% {
      transform:  translateX(0) scaleX(0);
  }
  40% {
      transform:  translateX(0) scaleX(0.4);
  }
  100% {
      transform:  translateX(100%) scaleX(0.5);
  }
}

/* Steps */
.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}
